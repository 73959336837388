#about-container {
    z-index: inherit;
    /* background-color: rgba(255, 255, 255, 0.5); */
    width: 800px;
    max-height: calc(100vh - 231px);
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Heebo', sans-serif;
    /* font-family: "Times New Roman", Times, serif; */
}

.about-container-sub {
    margin-top: 40px;
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    text-shadow: 5px 5px 20px grey;
    font-weight: 700;
    display: flex;
    justify-content: center;
}


.about-container-sub>div {
    font-size: 16px;
    width: 320px;
    height: fit-content;
    text-align: left;
    max-height: 60vh;
    margin: 0 16px;
    padding: 0 10px;
}

#about-scroll {
    /* margin-bottom: 10px; */
    height: 100%;
    overflow-y: scroll;
}
#about-scroll::-webkit-scrollbar{
    background: transparent;
    width: 8px;
    border-radius: 10px;
}
#about-scroll::-webkit-scrollbar-thumb {

    background: lightgrey;
    width: 8px;
    border-radius: 10px;
}

#about-images {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-container-sub>div>img {
    max-width: 280px;
    margin-bottom: 32px;
    border: 1px solid black;
}
