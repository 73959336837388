#home-container {
    z-index: 1;
    /* background-color: rgba(255, 255, 255, 0.5); */
    width: 800px;
    height: calc(100vh - 251px);
    display: flex;
    justify-content: center;
    font-family: 'Heebo', sans-serif;
}

#home-content {
    margin-top: 80px;
    font-size: 24px;
    text-align: center;
    width: 500px;
    /* line-height: 32px; */
    text-shadow: 5px 5px 20px grey;
    font-weight: 700;
}
