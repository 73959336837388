#contact-container {
    z-index: inherit;
    /* background-color: rgba(255, 255, 255, 0.5); */
    width: 800px;
    height: calc(100vh - 251px);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Heebo', sans-serif;

}

#contact-content-1 {
    margin-top: 40px;
    font-size: 24px;
    text-align: center;
    width: 400px;
    line-height: 32px;
    text-shadow: 5px 5px 20px grey;
    font-weight: 700;
}
#contact-content-2 {
    margin-top: 40px;
    font-size: 16px;
    text-align: center;
    width: 400px;
    line-height: 32px;
    text-shadow: 5px 5px 20px grey;
    font-weight: 700;
}
#contact-content-3 {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
    width: 400px;
    line-height: 32px;
    text-shadow: 5px 5px 20px grey;
    font-weight: 700;
}

#email {
    font-style: italic;
    color: black;
    /* text-decoration: none; */
}
