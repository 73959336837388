#nav-bar-container {
    /* background-color: lightgreen; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    /* background-color: rgba(255, 255, 255,0.5); */
    width: 800px;
}

#nav-name {
    font-size: 48px;
    text-align: center;
    font-family: 'Rye', cursive;
    opacity: 0.9;
    margin-top: 60px;
    text-shadow: 2px 2px 5px grey;

    /* -webkit-transform: scale(0.8, 1); */
}

#nav-location {
    font-size: 20px;
    font-family: 'Rye', cursive;
    /* opacity: 0.7; */
    text-align: center;
    margin-bottom: 60px;
    text-shadow: 2px 2px 5px grey;

}




#nav-list {
    display: flex;
    justify-content: space-between;
    width: 720px;
}

.nav-list-content {
    color: white;
    background-color: black;
    border: 1px solid lightgrey;
    width: 25%;
    text-align: center;
    font-weight: bold;
    padding: 3px;
}
