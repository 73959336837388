#background-image-main {
  position: absolute;
  /* z-index: 0; */
  opacity: 0.75;
  min-width: 100%;
  width: 100em;
  height: 100vh;
  min-height: 800px;
}

#app-container {
  /* background-color: pink; */
  /* opacity: 0.7; */
  width: 100%;
  min-height: 800px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.cursor {
  cursor: pointer;
}

#favicon {
  position: fixed;
  top:10px;
  left:10px;
  z-index: 99999;
  width: 20px;
  height: 20px;
}
