#instruments-container {
    z-index: inherit;
    /* background-color: rgba(255, 255, 255, 0.5); */
    width: 800px;
    height: calc(100vh - 251px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    font-family: 'Heebo', sans-serif;
    /* font-family: "Times New Roman", Times, serif; */


}
#instruments-scroll {
    /* margin-bottom: 10px; */
    height: 100%;
    overflow-y: scroll;
}

#instruments-scroll::-webkit-scrollbar {
    background: transparent;
    width: 8px;
    border-radius: 10px;
}
#instruments-scroll::-webkit-scrollbar-thumb {
    background: lightgrey;
    width: 8px;
    border-radius: 10px;
}

.instruments-container-sub {
    /* max-height: 80vh; */
    margin-top: 40px;
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    text-shadow: 5px 5px 20px grey;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.instruments-container-sub>div {
    font-size: 16px;
    width: 320px;
    /* background-color: pink; */
    height: fit-content;
    text-align: left;
    max-height: 60vh;
    margin: 0 16px;
    padding: 0 10px;
}

#instruments-images {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instruments-images-content {
    max-width: 280px;
    margin-bottom: 32px;
    border: 1px solid black;
}
.instruments-images-content-no-border {
    max-width: 280px;
    margin-bottom: 32px;
    border: none;
}
